import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CadastroContasFinanceiras.css';


const CadastroContasFinanceiras = () => {
  const [mascaraConta, setMascaraConta] = useState('');
  const [descricao, setDescricao] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleMascaraChange = (e) => {
    const value = e.target.value;
    const mascaraRegex = /^\d\.\d{2}\.\d{3}\.\d{4}$/; // Validação da máscara no formato 1.11.111.1111
    if (!mascaraRegex.test(value) && value !== '') {
      setErrorMessage('A máscara deve seguir o formato 1.11.111.1111.');
    } else {
      setErrorMessage('');
    }
    setMascaraConta(value);
  };

  const handleDescricaoChange = (e) => {
    const value = e.target.value;
    if (value.length > 100) {
      setErrorMessage('A descrição deve ter no máximo 100 caracteres.');
    } else {
      setErrorMessage('');
    }
    setDescricao(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mascaraConta || !descricao) {
      setErrorMessage('Todos os campos devem ser preenchidos.');
      return;
    }
    
    try {
      const response = await axios.post('https://financeiro.multipla.tec.br/api/contas-financeiras/', {
        mascara_conta: mascaraConta,
        descricao: descricao,
      });

      if (response.status === 201) {
        alert('Conta financeira cadastrada com sucesso!');
        navigate('/'); // Redireciona para a página inicial ou onde você desejar
      }
    } catch (error) {
      console.error('Erro ao cadastrar conta financeira:', error);
      setErrorMessage('Erro ao cadastrar a conta financeira.');
    }
  };

  const handleCancel = () => {
    navigate(-1); // Volta para a página anterior
  };

  const handleClear = () => {
    setMascaraConta('');
    setDescricao('');
    setErrorMessage('');
  };

  return (
    <div className="cadastro-contas-financeiras">
      <h1>Cadastro de Contas Financeiras</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Máscara da Conta Financeira:</label>
          <input
            type="text"
            value={mascaraConta}
            onChange={handleMascaraChange}
            placeholder="1.11.111.1111"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
        
        <div>
          <label>Descrição:</label>
          <input
            type="text"
            value={descricao}
            onChange={handleDescricaoChange}
            maxLength={100}
            placeholder="Máximo 100 caracteres"
          />
        </div>
        
        <div className="actions">
          <button type="submit">Salvar</button>
          <button type="button" onClick={handleCancel}>Cancelar</button>
          <button type="button" onClick={handleClear}>Limpar</button>
          <button type="button" onClick={handleCancel}>Voltar</button>
        </div>
      </form>
    </div>
  );
};

export default CadastroContasFinanceiras;
