import React, { useState } from 'react';
import axios from 'axios';
import './CadastroCentroCusto.css';

const CadastroCentroCusto = () => {
  const [descricao, setDescricao] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleDescricaoChange = (e) => {
    setDescricao(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = {
      descricao
    };

    try {
      const response = await axios.post('https://financeiro.multipla.tec.br/api/centros-custo/', data);
      setSuccessMessage('Centro de Custo cadastrado com sucesso!');
      setDescricao(''); // Limpa o campo após o sucesso
    } catch (error) {
      setErrorMessage('Erro ao cadastrar Centro de Custo. Tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClear = () => {
    setDescricao('');
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <div className="cadastro-centro-custo">
      <h1>SISTEMA FINANCEIRO - CADASTRO DE CENTROS DE CUSTO</h1>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="descricao">Descrição do Centro de Custo:</label>
          <input
            type="text"
            id="descricao"
            value={descricao}
            onChange={handleDescricaoChange}
            maxLength="100"
            required
            placeholder="Insira a descrição (máximo 100 caracteres)"
          />
        </div>

        <div className="form-group">
          <label htmlFor="mascara">Máscara do Centro de Custo:</label>
          <input
            type="text"
            id="mascara"
            value="Gerado automaticamente"
            readOnly
          />
        </div>

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="form-actions">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Salvando...' : 'Salvar'}
          </button>
          <button type="button" onClick={handleClear}>
            Limpar
          </button>
          <button type="button" onClick={() => window.history.back()}>
            Voltar
          </button>
        </div>
      </form>
    </div>
  );
};

export default CadastroCentroCusto;
